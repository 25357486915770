import { useEffect, useRef, useState } from 'react';
import { ReactComponent as CocktailSolid } from './cocktail-solid.svg';
import { ReactComponent as ArrowUpSolid } from './arrow-up-solid.svg';
import bg from './bocken.jpg';
import gastro from './gastro.png';
import './App.css';

function App() {
  const getDiff = () => {
    const targetTime = new Date('2025-10-25T14:00:00');
    const now = new Date();
    var diff = targetTime - now;
    console.log(targetTime, now, diff);
    return {
      days: (diff / (1000*60*60*24)).toFixed(0),
      hours: (diff / (1000*60*60) % 24).toFixed(0),
      minutes: (diff / (1000*60) % 60).toFixed(0),
      seconds: (diff / (1000) % 60).toFixed(0),
    };
  };
  const [diff, setDiff] = useState(getDiff());
  useEffect(() => {
    setInterval(() => setDiff(getDiff()), 1000);
  }, []);

  const intro = useRef();
  const prog = useRef();
  const catering = useRef();
  const donate = useRef();
  const beThere = useRef();
  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref?.current?.offsetTop || 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="App">
      <button className="scroll-to-top" onClick={() => scrollToRef()}>
        <ArrowUpSolid />
      </button>
      <header className="App-header" style={{background: `linear-gradient(
          rgba(0, 0, 20, 0.8), 
          rgba(0, 0, 20, 0.8)
        ),center center / cover no-repeat url(${bg})`}}>
        <div className="container centered">
          <CocktailSolid className="title-icon" />
          <h1>Millionärsgala</h1>
          <p className="intro">
            <b>100 Millionäre</b> in einem Raum, am <b>25.10.2025</b>
          </p>
          <div className="countdown">
            <div className="number">
              <div className="value">{diff?.days}</div>
              <div className="name">Tage</div>
            </div>
            <div className="separator">:</div>
            <div className="number">
              <div className="value">{diff?.hours}</div>
              <div className="name">Stunden</div>
            </div>
            <div className="separator">:</div>
            <div className="number">
              <div className="value">{diff?.minutes}</div>
              <div className="name">Minuten</div>
            </div>
            <div className="separator">:</div>
            <div className="number">
              <div className="value">{diff?.seconds}</div>
              <div className="name">Sekunden</div>
            </div>
          </div>
          <div className="link-wrapper">
            <span className="link" onClick={() => scrollToRef(intro)}>Intro</span>
            <span className="link" onClick={() => scrollToRef(prog)}>Program</span>
            <span className="link" onClick={() => scrollToRef(catering)}>Gastronomie</span>
            <span className="link" onClick={() => scrollToRef(donate)}>Spenden</span>
            <span className="link" onClick={() => scrollToRef(beThere)}>Dabei sein!</span>
          </div>
        </div>
      </header>


      <section className="App-section" ref={intro}>
        <div className="container">
          <p>
            Herzlich Willkommen, auf der Millionärsgala...<br />
            &nbsp;<br />
            ...wir sind begeistert, dass Sie den Weg hierher gefunden haben. Am 25.10.2025 feiern wir die Millionärsgala hier im Hotel Bocken in Horgen. Hier finden Sie alle wichtigen Informationen rund um unser Event. 
          </p>
          <p>
            Die Millionärs Gala bietet Ihnen die einmalige Gelegenheit Ihr Netzwerk auf- und auszubauen. 
            Im Rahmen unseres Programms bieten sich unzählige Möglichkeiten sich mit gleichgesinnten bewussten Menschen zu vernetzen und auszutauschen. Die daraus entstehenden Synergieeffekte können Ihr Business und Ihre Finanzen auf das nächste Level katapultieren. 
          </p>
          <p>
            Wir begeistern mit fundamentalem, praxiserprobtem Fachwissen und Fachvorträgen rund um die Themen:
          </p>
          <ul>
            <li>Nachhaltig Investieren</li>
            <li>Wertschriftendepot Optimierungen</li>
            <li>Geldbewusstsein und Reichtums-Mindset</li> 
            <li>So entfalten Sie Ihr wahres Potenzial</li>
          </ul>
          <p>
            Exklusiv zur Feier unserer Millionärsgala haben wir Speaker organisiert, die ihr Know-how und ihre Expertisen in genau diesen Bereichen mit uns teilen.
            Unser primäres Ziel ist es Sie in Ihrer finanziellen Weiterbildung zu unterstützen und Ihr Wissen und Verständnis zu vertiefen, damit Sie zukünftig noch mehr Wohlstand und Unabhängigkeit aufbauen können.
          </p>
          <p>
            Neben all den Feierlichkeiten und der Weiterbildung wird es auch die Möglichkeit geben zu spenden. Uns liegt das Wohl der Welt und der Menschen sehr am Herzen, darum stellen wir Ihnen an diesem Abend einige unserer Herzensprojekte vor.
          </p>
          <p>
            Die Gala findet in einer einmaligen Location im Hotel Bocken in Horgen statt. 
          </p>
          <p>
            Die Räumlichkeiten schaffen den Spagat zwischen Räumen um sich weiterzubilden, auszutauschen und zu lernen sowie passende Säle, die das richtige Ambiente schaffen um zu feiern. Das abendliche Unterhaltungsprogramm ist vielfältig aufgebaut und bietet für jeden Geschmack etwas.
          </p>
          <p>
            Es besteht die Möglichkeit ein Hotelzimmer zusätzlich dazu zu buchen. Alle weiteren Infos zur Übernachtung findest du hier. 
          </p>
        </div>
      </section>
      <section className="App-section" ref={prog}>
        <div className="container">
          <h2>Program</h2>
          <table>
            <thead>
              <tr>
                <th>Wann?</th>
                <th>Was?</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>14:00&nbsp;Uhr</td>
                <td>Apero / Netzwerken</td>
              </tr>
              <tr>
                <td>15:00&nbsp;Uhr</td>
                <td>
                  Referat<br />
                  <i>Thema: Nachhaltigkeit, Wertschriftendepots, US&nbsp;Policen</i>
                </td>
              </tr>
              <tr>
                <td>16:00&nbsp;Uhr</td>
                <td>
                  Referat<br />
                  <i>Thema: Entfalte dein wahres Potenzial</i>
                </td>
              </tr>
              <tr>
                <td>17:00&nbsp;Uhr</td>
                <td>Präsentation verschiedener Spendenaktionen</td>
              </tr>
              <tr>
                <td>18:00&nbsp;Uhr</td>
                <td>Break / Netzwerken</td>
              </tr>
              <tr>
                <td>19:00&nbsp;Uhr</td>
                <td>Beginn Galadinner / Klavierkonzert / Feuerwerk / musikalische Unterhaltung</td>
              </tr>
              <tr>
                <td>22:00&nbsp;Uhr</td>
                <td>Beginn DJ Abendprogram / Tanzen &amp; Bar</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>


      <div className="hero image" style={{backgroundImage: "url('https://unsplash.com/photos/WStAHvywOZU/download?w=1920')"}}></div>


      <section className="App-section" ref={catering}>
        <div className="container">
          <h2>Gastronomie</h2>
          <p>
            “Eine gute Küche ist das Fundament allen Glücks.”  Auguste Escoffier
          </p>
          <p>
            Unser Gastronomie Team freut sich Sie an unserer Gala willkommen zu heissen. 
          </p>
          <p>
            Das Team rund um die Gastronomie ist stets da und bietet eine köstliche Auswahl an Speisen, Leckereien und Getränken. Unsere Gerichte und Menüs bieten Ihnen ein schönes Spektrum an kulinarischen Genüssen und Highlights.
            Exquisite süße Gebäcke und Desserts warten darauf von Ihnen probiert zu werden. 
            In der Küche wird mit sehr viel Liebe zum Detail gearbeitet. Für Ihr Wohlbefinden ist rundum gesorgt.
          </p>
          <p>
            Wir bitten Sie im Bewerbungsformular Ihre bevorzugte Ernährungsform anzugeben, damit wir ganz auf Ihre persönlichen Bedürfnisse eingehen können.
          </p>
          <p>
            Wir freuen uns auf Sie.
          </p>
          <p>
            Ihr Gastronomie Team
          </p>

          <img src={gastro} alt="Gastro" />
        </div>
      </section>


      <div className="hero image" style={{backgroundImage: "url('https://unsplash.com/photos/InxB1SWvuwQ/download?w=1920')"}}></div>


      <section className="App-section" ref={donate}>
        <div className="container">
          <h2>Spendenprojekte</h2>
          <p>
            “the secret of living is giving” Tony Robbins
          </p>
          <p>
            Nachhaltige Spenden Projekte liegen uns sehr am Herzen und dementsprechend stellen wir einige unserer Herzensprojekte an diesem Abend vor. Diese Projekte sind uns selbst sehr wichtig und wir freuen uns diese mit Ihnen teilen zu können. 
          </p>
          <p>
            Wir legen dabei sehr grossen Wert auf die Authentizität und Werte der Spendenprojekte.
            Geld ist Energie, darum unterstützen wir nur Organisationen hinter denen wir auch hundertprozentig stehen können. 
            Spenden ist einfach, doch eine Organisation zu finden, die wirklich das lebt, was sie verspricht, ist eine Kunst. 
            Denn nichts ist schlimmer, als wenn Spenden in irgendwelchen Bürokratie Apparaten verbrennen. Das hilft niemandem etwas und macht diese Welt nicht zu einem schöneren und besseren Ort.
            Aus den oben genannten Gründen sind unsere Projekte auch sehr sorgfältig ausgewählt und erfüllen alle unsere Standards an eine seriöse und nachhaltige Spendenorganisation.
          </p>
          <p>
            Das Gesetz der Vibration ist auf jeden Fall mit Ihnen, alles was Sie aus tiefer Dankbarkeit und Liebe verschenken, kommt auch wieder zu Ihnen zurück.
          </p>
          <h2>
            Versteigerung für guten Zweck
          </h2>
          <p>
            Anlässlich unserer Gala haben wir uns etwas besonderes überlegt. Wir veranstalten eine Versteigerung für gute Zwecke. Sämtliche Einnahmen werden an eine unserer Spendenorganisationen gespendet.
          </p>
          <p>
            Wir haben ein paar originelle Überraschungen am Start, die Sie ersteigern können. 
          </p>
        </div>
      </section>

      <section className="App-section" ref={beThere}>
        <div className="container">
          <h2 className="text-center">Dabei sein!</h2>
          <a className="button" href="http://eepurl.com/hLB48L">
            Infos für den Tag erhalten
          </a>
        </div>
      </section>


      <footer className="App-footer">
        <div className="container">
          <p>
            &copy; {new Date().getFullYear()} | <a href="https://iffb.ch">Institut für Finanzbildung</a> | Icons von <a href="https://fontawesome.com/">Font Awesome</a>
          </p> 
        </div>
      </footer>
    </div>
  );
}

export default App;
